import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import { Table } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';


const ThankYou = () => {
  const [customerInfo, setCustomerInfo] = useState(null);
  const [pointsInfo, setPointsInfo] = useState([]);
  const { width, height } = useWindowSize();

  useEffect(() => {
    const savedCustomerInfo = JSON.parse(localStorage.getItem('customerInfo'));
    const savedPointsInfo = JSON.parse(localStorage.getItem('pointsInfo'));

    if (savedCustomerInfo) setCustomerInfo(savedCustomerInfo);
    if (savedPointsInfo) setPointsInfo(savedPointsInfo);
  }, []);

  return (
    <div>
      <Confetti width={width} height={height} /> 
      <Nav />
      <div className="container mt-5 text-center">
        <h2>Cảm ơn quý khách đã lựa chọn Ciné Saigon</h2>
        <p>Đặt bàn của quý khách đã được gửi thành công. Bộ phận CSKH sẽ liên lạc trong thời gian sớm nhất!</p>
        
        {customerInfo && (
          <>
            <h3>Dưới đây là thông tin đặt bàn của quý khách</h3>
            <h5>Thông tin khách hàng</h5>
            <Table striped bordered hover variant="dark">
              <tbody>
                <tr>
                  <td><strong>Tên</strong></td>
                  <td>{customerInfo.name}</td>
                </tr>
                <tr>
                  <td><strong>Email</strong></td>
                  <td>{customerInfo.email}</td>
                </tr>
                <tr>
                  <td><strong>Số điện thoại</strong></td>
                  <td>{customerInfo.phone}</td>
                </tr>
                <tr>
                  <td><strong>Số lượng khách</strong></td>
                  <td>{customerInfo.guestCount}</td>
                </tr>
                <tr>
                  <td><strong>Nhân dịp</strong></td>
                  <td>{customerInfo.occasion}</td>
                </tr>
                <tr>
                  <td><strong>Yêu cầu đặc biệt</strong></td>
                  <td>{customerInfo.specialRequests}</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}

        {pointsInfo.length > 0 && (
          <>
            <h5>Thông tin đặt bàn</h5>
            <Table striped bordered hover variant="dark" className='mt-4'>
              <thead>
                <tr>
                  <th>Bàn</th>
                  <th>Ngày</th>
                  <th>Trạng thái</th>
                  <th>Chi tiêu tối thiểu</th>
                </tr>
              </thead>
              <tbody>
                {pointsInfo.map((point, index) => (
                  <tr key={index}>
                    <td>{point.pointName}</td>
                    <td>{point.date}</td>
                    <td>{point.status}</td>
                    <td>{point.price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
