// src/Admin/ResetPoints.js
import React from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const ResetPoints = ({ fetchPoints, selectedDate }) => {
  const handleReset = async () => {
    try {
      await axios.post(`${apiUrl}/api/admin/points/reset`);
      alert('All points have been reset to available.');
      // Gọi lại fetchPoints để cập nhật danh sách điểm
      fetchPoints(selectedDate);
    } catch (err) {
      alert('Failed to reset points');
      console.error('Error resetting points:', err);
    }
  };

  return (
    <div>
      <button onClick={handleReset}>Reset All Points</button>
    </div>
  );
};

export default ResetPoints;
