import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import { Modal, Button } from 'react-bootstrap';
import PointMap from './components/PointMap';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import ThankYou from './components/ThankYou';
import AdminDashboard from './components/Admin/AdminDashboard';
import OrderManagement from './components/Admin/OrderManagement';
import PointManagement from './components/Admin/PointManagement';
import OrderConfirm from './components/Admin/OrderConfirm';
import Nav from './components/Nav';

const apiUrl = process.env.REACT_APP_API_URL;


const socket = io(apiUrl);

const getUserId = () => {
  let userId = localStorage.getItem('userId');
  if (!userId) {
    userId = `user_${Math.random().toString(36).substr(2, 9)}`;
    localStorage.setItem('userId', userId);
  }
  return userId;
};

const App = () => {
  const userId = getUserId();
  const [message, setMessage] = useState("VIP BOOKING");
  const [points, setPoints] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const navigate = useNavigate();

  const fetchAvailableDates = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/points/dates`);


      setAvailableDates(response.data);
    } catch (error) {
      console.error("Error fetching available dates:", error);
    }
  };

  const fetchPoints = async (date) => {
    try {
      const response = await axios.get(`${apiUrl}/api/points?date=${date}`);
      setPoints(response.data);
      setMessage("Select points to book");
    } catch (error) {
      console.error("Error fetching points:", error);
      setMessage("Failed to load points for the selected date.");
    }
  };
  useEffect(() => {
    socket.on('limitReached', (data) => {
      alert(data.message);
    });
  
    socket.on('error', (data) => {
      alert(data.message);
    });
  
    return () => {
      socket.off('limitReached');
      socket.off('error');
    };
  }, []);
  useEffect(() => {
    fetchAvailableDates();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchPoints(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    socket.on('updatePoint', (updatedPoint) => {
      setPoints((prevPoints) =>
        prevPoints.map((point) =>
          point._id === updatedPoint._id ? updatedPoint : point
        )
      );
    });

    socket.on('pointTaken', (data) => {
      alert(data.message);
    });

    socket.on('cartUpdated', () => {
      fetchPoints(selectedDate);
    });

    return () => {
      socket.off('updatePoint');
      socket.off('pointTaken');
      socket.off('cartUpdated');
    };
  }, [selectedDate]);

  const handleDateChange = (event) => {
    const date = event.target.value;

    // Kiểm tra nếu có điểm nào đã chọn và ngày khác với ngày đã chọn trước đó
    if (selectedPoints.length > 0 && date !== selectedDate) {
      // Thông báo cho người dùng về việc chưa hoàn tất booking hiện tại
      alert(
        `Bạn đang có các điểm đã chọn ở ngày ${selectedDate}. ` +
        `Vui lòng hoàn tất booking này trước khi chọn ngày khác!`
      );
      return;
    }

    setSelectedDate(date);
    setIsDateSelected(true);
    fetchPoints(date);
  };

  const handleBook = (pointId) => {
    socket.emit('bookPoint', { pointId, userId });
  };

  const proceedToCheckout = (points) => {
    setSelectedPoints(points);
    setShowCheckoutPopup(true);
  };

  const closeCheckoutPopup = () => {
    setShowCheckoutPopup(false);
  };

  const handleCheckoutSuccess = () => {
    // Điều hướng đến trang "Thank You"
    navigate('/thank-you');
  };

  return (
    <Routes>
      <Route path="/" element={
        <div>
          <Nav />
          <div className="container mt-4">
            
            {/* Hiển thị mục chọn ngày hoặc thông báo ngày đã chọn */}
            {!isDateSelected ? (
              <div className="step-select">
                <h1 className="mb-4">{message}</h1>
                <video src="/tvc.mp4"autoPlay muted loop playsInline className="w-100 video-bg" type="video/mp4"></video>
                <select id="date-select" className="form-control" value={selectedDate} onChange={handleDateChange}>
                  <option value="">Vui lòng chọn ngày</option>
                  {availableDates && Array.isArray(availableDates) && availableDates.map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <p className='text-center'>Bạn đang Booking cho ngày: <strong>{selectedDate}</strong></p>
            )}

          {isDateSelected && (
              <div className="row">
                <div className="col-lg-7">
                  <PointMap points={points} onBook={handleBook} />
                </div>
                <div className="col-lg-5">
                  <Cart userId={userId} selectedDate={selectedDate} onCheckout={proceedToCheckout} />
                </div>
              </div>
            )}

          {/* Modal Popup for Checkout */}
          <Modal show={showCheckoutPopup} onHide={closeCheckoutPopup} backdrop="static" centered>
            <Modal.Header closeButton>
              <Modal.Title>Thông tin khách hàng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Checkout 
                cartPoints={selectedPoints} 
                closeCheckout={closeCheckoutPopup} 
                onCheckoutSuccess={handleCheckoutSuccess}
              />
            </Modal.Body>
          </Modal>
        </div>
        </div>
      }/>
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/admin" element={<OrderManagement />} />
      <Route path="/admin/points" element={<PointManagement />} />
      <Route path="/admin/confirm" element={<OrderConfirm />} />
    </Routes>
  );
};

export default App;
