// src/components/OrderConfirm.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Modal, Button, Table } from 'react-bootstrap';
import AdminNav from './AdminNav';
const apiUrl = process.env.REACT_APP_API_URL;
const OrderConfirm = () => {
  const [orders, setOrders] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/admin/orders-with-time`);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());

      const upcomingOrders = orders.filter(order => new Date(order.exactlyTime) > new Date());
      if (upcomingOrders.length > 0) {
        const closestOrder = upcomingOrders.reduce((prev, curr) =>
          new Date(prev.exactlyTime) < new Date(curr.exactlyTime) ? prev : curr
        );
        const timeRemaining = new Date(closestOrder.exactlyTime) - new Date();
        setCountdown(timeRemaining > 0 ? timeRemaining : null);
      } else {
        setCountdown(null);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [orders]);

  const timeZone = 'Asia/Ho_Chi_Minh';

  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = parseISO(dateString);
      const zonedDate = utcToZonedTime(date, timeZone);
      return format(zonedDate, "dd/MM/yyyy 'lúc' HH'h'mm");
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const formatCountdown = (milliseconds) => {
    if (milliseconds === null) return "No upcoming orders";
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handleShowDetails = (order) => {
    setSelectedOrder(order);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedOrder(null);
  };

  const handleComplete = async (orderId) => {
    try {
      await axios.put(`${apiUrl}/api/admin/orders/${orderId}/status`, { orderStatus: 'Completed' });

      const orderResponse = await axios.get(`${apiUrl}/api/admin/orders/${orderId}`);
      const order = orderResponse.data;
      const pointIds = order.selectedPoints.map(point => point.pointId);

      if (pointIds.some(pointId => !pointId)) {
        alert('One or more point IDs are missing.');
        return;
      }

      await axios.put(`${apiUrl}/api/points/update-status`, {
        points: pointIds,
        status: 'Sold'
      });

      alert('Order and points updated to Sold status successfully');

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, orderStatus: 'Completed' } : order
        )
      );
    } catch (error) {
      console.error('Error updating point status:', error);
      alert('Failed to update order and points status');
    }
  };

  const handleDeleteOrder = async (orderId) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      try {
        await axios.delete(`${apiUrl}/api/admin/orders/${orderId}`);
        setOrders(orders.filter(order => order._id !== orderId));
        alert('Order deleted successfully');
      } catch (error) {
        console.error('Error deleting order:', error);
        alert('Failed to delete order');
      }
    }
  };

  return (
    <div><AdminNav />
    <div className="container mt-4">
      <h1>Upcoming Orders</h1>

      <div className="mb-4">
        <strong>Thời gian hiện tại:</strong> {formatDateTime(currentTime.toISOString())} GMT + 7
      </div>
      <div className="mb-4 ">
        <strong>Thời gian đến Booking gần nhất:</strong> {formatCountdown(countdown)}
      </div>

      <Table striped bordered hover variant="dark" className='mt-4'>
        <thead>
          <tr>
            <th>Vị trí</th>
            <th>Ngày</th>
            <th>Thời gian tạo đơn</th>
            <th>Thời gian đến</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order._id}>
              <td>
                <div className="list-point">{order.selectedPoints.map(point => (
                  <div key={point._id}>{point.pointName}</div>
                ))}</div>
              </td>
              <td>{order.selectedPoints[0]?.date || 'Unknown Date'}</td>
              <td>{formatDateTime(order.createdAt)}</td>
              <td>{formatDateTime(order.exactlyTime)}</td>
              <td>
                <Button variant="info" size="sm" onClick={() => handleShowDetails(order)}>
                  Xem chi tiết
                </Button>{' '}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showDetails} onHide={handleCloseDetails} dialogClassName="modal-dialog-scrollable">
        <Modal.Header closeButton>
          <Modal.Title>Thông tin Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <>
              <h5>Thông tin khách hàng</h5>
              <p><strong>Name:</strong> {selectedOrder.customerInfo.name}</p>
              <p><strong>Email:</strong> {selectedOrder.customerInfo.email}</p>
              <p><strong>Phone:</strong> {selectedOrder.customerInfo.phone}</p>
              <p><strong>Số lượng khách:</strong> {selectedOrder.customerInfo.guestCount}</p>
              <p><strong>Nhân dịp:</strong> {selectedOrder.customerInfo.occasion}</p>
              <p><strong>Yêu cầu:</strong> {selectedOrder.customerInfo.specialRequests}</p>
              <hr />
              <h5>Thông tin booking</h5>
              {selectedOrder.selectedPoints.map(point => (
                <div key={point._id}>
                  <p><strong>Vị trí:</strong> {point.pointName}</p>
                  <p><strong>Ngày:</strong> {point.date}</p>
                  <p><strong>Trạng thái:</strong> {point.status}</p>
                  <p><strong>Minium Spend:</strong> {point.price}</p>
                  <hr />
                </div>
              ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleComplete(selectedOrder._id)}>
            Xác nhận
          </Button>
          <Button variant="danger" onClick={() => handleDeleteOrder(selectedOrder._id)}>
            Xóa
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>
  );
};

export default OrderConfirm;
