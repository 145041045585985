// src/components/Checkout.js

import React, { useState } from 'react';
import axios from 'axios';
import { zonedTimeToUtc } from 'date-fns-tz';



const Checkout = ({ cartPoints, closeCheckout, onCheckoutSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    guestCount: 1,
    occasion: '',
    specialRequests: '',
    time: '',
  });
  
  const [loading, setLoading] = useState(false); // Thêm trạng thái loading

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const generateTimeSlots = () => {
    const slots = [];
    let current = new Date();
    current.setHours(21, 0, 0, 0); // Bắt đầu từ 21h
  
    const end = new Date();
    end.setHours(2, 0, 0, 0); // Kết thúc ở 2h sáng ngày hôm sau
    end.setDate(end.getDate() + 1); // Chuyển sang ngày hôm sau
  
    while (current <= end) {
      const hours = current.getHours().toString().padStart(2, '0');
      const minutes = current.getMinutes().toString().padStart(2, '0');
      slots.push(`${hours}:${minutes}`);
      current.setMinutes(current.getMinutes() + 30); // Cách nhau 30 phút
    }
  
    return slots;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Bật trạng thái loading

    const customerInfo = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      guestCount: formData.guestCount,
      occasion: formData.occasion,
      specialRequests: formData.specialRequests,
      time: formData.time,
    };

    console.log('Customer Info:', customerInfo); // Kiểm tra giá trị customerInfo

    // Thêm giá vào các điểm trong selectedPoints
    const updatedPoints = cartPoints.map(point => ({
      ...point,
      price: point.price || 1000000,  // Đảm bảo rằng price được cung cấp, nếu không sẽ đặt giá mặc định
    }));

    // Xử lý exactlyTime bằng cách sử dụng zonedTimeToUtc
    const timeZone = 'Asia/Ho_Chi_Minh';
    const pointDate = updatedPoints[0].date; // 'YYYY-MM-DD'
    const customerTime = formData.time;      // 'HH:mm'

    // Kết hợp ngày và giờ thành chuỗi datetime
    const exactlyTimeString = `${pointDate}T${customerTime}:00`;
    console.log('Exactly Time String:', exactlyTimeString);

    // Chuyển đổi sang UTC
    const exactlyTime = zonedTimeToUtc(exactlyTimeString, timeZone);
    console.log('Exactly Time (UTC):', exactlyTime);

    // Chuẩn bị dữ liệu đơn hàng
    const orderData = {
      customerInfo,
      selectedPoints: updatedPoints.map(point => ({
        pointName: point.pointName,
        date: point.date,
        status: 'Pending',  // Cập nhật trạng thái tại đây
        price: point.price,
        pointId: point._id,
      })),
      exactlyTime, // Sử dụng giá trị exactlyTime đã chuyển đổi
    };

    try {
      // Gửi thông báo cho Admin qua Telegram và email (nếu bạn có API này)
       await axios.post('https://booking.cinesaigon.vn/api/notify-admin', {
        customerInfo,
       selectedPoints: orderData.selectedPoints,
       exactlyTime,
       });

      // Cập nhật trạng thái của các điểm
      await axios.put('https://booking.cinesaigon.vn/api/points/update-status', {
        points: updatedPoints.map(point => point._id),
        status: 'Pending',
        exactlyTime, // Gửi exactlyTime để cập nhật nếu cần
      });

      // Lưu đơn hàng vào cơ sở dữ liệu
      const response = await axios.post('https://booking.cinesaigon.vn/api/admin/orders', orderData);
      console.log("Order created:", response.data);

      // Lưu thông tin vào localStorage
    localStorage.setItem('customerInfo', JSON.stringify(customerInfo));
    localStorage.setItem('pointsInfo', JSON.stringify(updatedPoints));

    alert("Đơn hàng của bạn đã được gửi thành công!");
    closeCheckout();
    onCheckoutSuccess();
  } catch (error) {
    console.error("Error sending notification or saving order:", error);
    alert("Lỗi khi gửi thông báo hoặc lưu đơn hàng.");
  } finally {
    setLoading(false); // Tắt trạng thái loading sau khi hoàn thành yêu cầu
  }
};

  return (
    <div>
      {loading ? (
        <div className="loading-overlay">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
    <form id="customer-form" onSubmit={handleSubmit}>
      <div className="mb-md-3">
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="Họ và tên"
          required
          onChange={handleChange}
        />
      </div>
      <div className="row mb-md-3">
        <div className="col-md-6">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Địa chỉ email"
            required
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            name="phone"
            placeholder="Số điện thoại"
            required
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row mb-md-3">
        <div className="col-md-6">
          <input
            type="number"
            name="guestCount"
            placeholder="Số lượng khách"
            className="form-control"
            min="1"
            max="50"
            required
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <input
            type="text"
            name="occasion"
            className="form-control"
            placeholder="Nhân dịp"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-md-3">
  <select
    name="time"
    className="form-control"
    required
    value={formData.time}
    onChange={handleChange}
  >
    <option value="" disabled>Thời gian đến</option>
    {generateTimeSlots().map((slot, index) => (
      <option key={index} value={slot}>{slot}</option>
    ))}
  </select>
</div>


      <div className="mb-md-3">
        <textarea
          name="specialRequests"
          className="form-control"
          rows="4"
          placeholder="Yêu cầu đặc biệt nếu có"
          onChange={handleChange}>
        </textarea>
      </div>
      
      <button type="submit" className="btn btn-primary">Book now</button>
    </form>
  )}
  </div>
);
};

export default Checkout;
