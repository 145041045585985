// src/components/Admin/PointManagement.js
import React, { useState } from 'react';
import axios from 'axios';
import AdminNav from './AdminNav';
import PointList from './PointList';
const apiUrl = process.env.REACT_APP_API_URL;
const PointManagement = () => {
  const [points, setPoints] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  // Fetch points based on selected date
  const fetchPoints = async (date) => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin/points?date=${date}`);
      setPoints(response.data); // Cập nhật state với dữ liệu điểm
    } catch (error) {
      console.error('Error fetching points:', error);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    fetchPoints(e.target.value); // Lấy danh sách point theo ngày đã chọn
  };

  return (
    <div>
      <AdminNav />
      <div className="container">
        <h1 className="mb-4">Point Management</h1>
        {/* Phần quản lý điểm */}
        <div className="form-group">
          <label>Chọn ngày: </label>
          <input type="date" className="form-control" value={selectedDate} onChange={handleDateChange} />
        </div>

        {selectedDate && (
          <>
            <h3 className="mt-4">Điểm vào ngày {selectedDate}</h3>
            <PointList
              points={points}
              fetchPoints={fetchPoints}
              selectedDate={selectedDate}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PointManagement;
