// src/components/Point.js
import React from 'react';
import './Point.css'; // Import tệp CSS nếu có

const Point = ({ id, status, coordinates, onBook, disabled, pointName }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case 'Available':
        return 'point-available';
      case 'Booking':
        return 'point-booking';
      case 'Pending':
        return 'point-pending';
      case 'Sold':
        return 'point-sold';
      default:
        return '';
    }
  };

  const statusClass = getStatusClass(status);

  const handleClick = () => {
    if (status === 'Sold') {
      return;
    }
    onBook();
  };

  return (
    <div
      className={`point ${statusClass}`}
      style={{
        position: 'absolute',
        left: `${coordinates.x}px`,
        top: `${coordinates.y}px`,
        
      }}
      onClick={handleClick}
      title={status === 'Sold' ? 'Điểm này đã được bán' : 'Click để đặt chỗ'}
    >
      {pointName}
    </div>
  );
};

export default Point;
