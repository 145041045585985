// src/components/PointMap.js
import React, { useRef, useState, useEffect } from 'react';
import Point from './Point';

const PointMap = ({ points, onBook }) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageMapRef = useRef(null);

  // Tính toán tọa độ pixel từ tọa độ phần trăm
  const getPxCoordinates = (coordinates) => ({
    x: (coordinates.x / 100) * imageDimensions.width,
    y: (coordinates.y / 100) * imageDimensions.height,
  });

  // Cập nhật kích thước của hình ảnh khi thay đổi kích thước màn hình
  useEffect(() => {
    const updateImageDimensions = () => {
      if (imageMapRef.current) {
        setImageDimensions({
          width: imageMapRef.current.offsetWidth,
          height: imageMapRef.current.offsetHeight,
        });
      }
    };

    const resizeObserver = new ResizeObserver(updateImageDimensions);
    if (imageMapRef.current) {
      resizeObserver.observe(imageMapRef.current);
    }

    // Dọn dẹp khi component bị tháo gỡ
    return () => {
      if (imageMapRef.current) {
        resizeObserver.unobserve(imageMapRef.current);
      }
    };
  }, []);

  return (
    <div>
    <div
      className='bar-map'
      ref={imageMapRef}
      style={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        backgroundImage: 'url("/map.png")',
        backgroundSize: 'cover',
        aspectRatio: '4013 / 4001',
      }}
    >
      {points.map((point) => {
        const pxCoordinates = getPxCoordinates(point.coordinates);

        return (
          <Point
            key={point._id}
            id={point._id}
            status={point.status}
            coordinates={pxCoordinates}
            onBook={() => onBook(point._id)}  // Đảm bảo onBook nhận id
            pointName={point.pointName}
          />
        );
      })}
    </div>

    <div className='bar-note'>
      <p className='bar-note_txt'>Quý khách vui lòng chọn bàn để đặt trước</p>
      <div className='bar-note_point'>
        <div className='point point-available'></div><div>: Bàn đang có sẵn</div>
      </div>
      <div className='bar-note_point'>
        <div className='point point-booking'></div><div>: Bàn đang có người đặt</div>
      </div>
      <div className='bar-note_point'>
        <div className='point point-sold'></div><div>: Bàn đã được đặt</div>
      </div>
    </div>
    </div>
  );
};

export default PointMap;
