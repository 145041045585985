// src/components/Cart.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { Button, Table } from 'react-bootstrap';
const apiUrl = process.env.REACT_APP_API_URL;




const socket = io(apiUrl);

const Cart = ({ userId, selectedDate, onCheckout }) => {
  const [cartPoints, setCartPoints] = useState([]);

  let lastExecutionTime = 0;
const throttleDelay = 1000; // Giới hạn gọi hàm mỗi giây

const fetchCartPoints = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/points/cart/${userId}`);
    setCartPoints(response.data);
  } catch (error) {
    console.error("Error fetching cart points:", error);
  }
};

useEffect(() => {
  const throttledFetchPoints = () => {
    const now = Date.now();
    if (now - lastExecutionTime > throttleDelay) {
      lastExecutionTime = now;
      fetchCartPoints();
    }
  };

  throttledFetchPoints(); // Gọi hàm fetch lần đầu

  socket.on('cartUpdated', throttledFetchPoints);
  socket.on('updatePoint', throttledFetchPoints);

  return () => {
    socket.off('cartUpdated', throttledFetchPoints);
    socket.off('updatePoint', throttledFetchPoints);
  };
}, [userId]); // Chỉ cần theo dõi userId để gọi lại hàm khi có thay đổi


  const handleDelete = async (pointId) => {
    try {
      await axios.put(`${apiUrl}/api/points/${pointId}`, { status: 'Available' });
      setCartPoints(cartPoints.filter((point) => point._id !== pointId));
      socket.emit('updatePointAfterDelete', pointId);
    } catch (error) {
      console.error("Error removing point from cart:", error);
    }
  };

  const totalPrice = cartPoints.length * 1000000;

  return (
    <div>
      <h2>Danh sách Booking</h2>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Bàn</th>
            <th>Ngày</th>
            <th>Chi phí tối thiểu</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {cartPoints.length > 0 ? (
            cartPoints.map((point) => (
              <tr key={point._id}>
                <td>{point.pointName}</td>
                <td>{point.date || selectedDate}</td>
                <td>1,000,000 VND</td>
                <td>
                  <Button variant="danger" onClick={() => handleDelete(point._id)}>Xóa</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">Danh sách đang trống</td>
            </tr>
          )}
          {cartPoints.length > 0 && (
            <tr>
              <td colSpan="2">Tổng cộng</td>
              <td colSpan="2">{totalPrice.toLocaleString()} VND</td>
            </tr>
          )}
        </tbody>
      </Table>
      {cartPoints.length > 0 && (
        <Button
          variant="primary"
          onClick={() => onCheckout(cartPoints)} // Truyền `cartPoints` vào `onCheckout`
          className="mt-3"
        >
          Book
        </Button>
      )}
    </div>
  );
};

export default Cart;