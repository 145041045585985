// src/components/Nav.js
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark mb-4">
      <div className="container">
        <Link className="navbar-brand" to="https://cinesaigon.vn/"><img src="/logo.png" className='logo'/></Link>
        
      </div>
    </nav>
  );
};

export default Nav;
