// src/components/Admin/PointEdit.js
import React, { useState } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const PointEdit = ({ point, fetchPoints, selectedDate }) => {
  const [price, setPrice] = useState(point.price || 1000000);

  const handlePriceChange = async () => {
    try {
      await axios.put(`${apiUrl}/api/admin/points/${point._id}/price`, { price });
      alert('Price updated successfully');
      // Gọi lại fetchPoints để cập nhật danh sách điểm
      fetchPoints(selectedDate);
    } catch (err) {
      alert('Failed to update price');
      console.error('Error updating price:', err);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <input
        type="number"
        className="form-control form-control-sm"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        style={{ width: '100px' }}
      />
      <button className="btn btn-primary btn-sm ml-2" onClick={handlePriceChange}>
        Cập nhật giá
      </button>
    </div>
  );
};

export default PointEdit;
