// src/components/Admin/PointList.js
import React from 'react';
import axios from 'axios';
import PointEdit from './PointEdit';
import ResetPoints from './ResetPoints';
const apiUrl = process.env.REACT_APP_API_URL;
const PointList = ({ points, fetchPoints, selectedDate }) => {
  // Hàm để reset trạng thái của một điểm
  const handleResetPointStatus = async (pointId) => {
    try {
      await axios.put(`${apiUrl}/api/admin/points/${pointId}/reset-status`);
      alert('Point status reset to available.');
      // Gọi lại fetchPoints để cập nhật danh sách điểm
      fetchPoints(selectedDate);
    } catch (error) {
      console.error('Error resetting point status:', error);
    }
  };

  return (
    <div>
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>Tên điểm</th>
            <th>Trạng thái</th>
            <th>Giá</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {points.map((point, index) => (
            <tr key={point._id}>
              <td>{index + 1}</td>
              <td>{point.pointName}</td>
              <td>{point.status}</td>
              <td>{point.price || 1000000}</td>
              <td>
                <div className="d-flex align-items-center">
                  <PointEdit
                    point={point}
                    fetchPoints={fetchPoints}
                    selectedDate={selectedDate}
                  />
                  <button
                    className="btn btn-warning btn-sm ml-2"
                    onClick={() => handleResetPointStatus(point._id)}
                  >
                    Reset Status
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResetPoints fetchPoints={fetchPoints} selectedDate={selectedDate} />
    </div>
  );
};

export default PointList;
