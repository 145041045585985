// src/components/Admin/OrderManagement.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminNav from './AdminNav';
import { Modal, Button, Form } from 'react-bootstrap';
const apiUrl = process.env.REACT_APP_API_URL;
const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/admin/orders`);
        setOrders(response.data);
        setFilteredOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const handleUpdateOrderStatus = async (orderId, newStatus) => {
    try {
      await axios.put(`${apiUrl}/api/admin/orders/${orderId}/status`, { orderStatus: newStatus });
      
      const orderResponse = await axios.get(`${apiUrl}/api/admin/orders/${orderId}`);
      const order = orderResponse.data;

      const pointIds = order.selectedPoints.map(point => point.pointId);
      if (pointIds.some(pointId => !pointId)) {
        alert('One or more point IDs are missing.');
        return;
      }

      await axios.put(`${apiUrl}/api/points/update-status`, {
        points: pointIds,
        status: 'Sold'
      });

      alert('Order status and points updated successfully');

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
      applyFilters();
    } catch (error) {
      console.error('Error updating order status and points:', error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await axios.delete(`${apiUrl}/api/admin/orders/${orderId}`);
      alert('Order deleted');
      setOrders((prevOrders) => prevOrders.filter((order) => order._id !== orderId));
      applyFilters();
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleViewOrderDetails = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };

  const applyFilters = () => {
    const filtered = orders.filter(order => {
      const matchesName = nameFilter ? order.customerInfo.name.toLowerCase().includes(nameFilter.toLowerCase()) : true;
      const matchesStatus = statusFilter ? order.orderStatus === statusFilter : true;
      const matchesDate = dateFilter
        ? order.selectedPoints.some(point => point.date === dateFilter)
        : true;
      return matchesName && matchesStatus && matchesDate;
    });
    setFilteredOrders(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [nameFilter, statusFilter, dateFilter, orders]);

  return (
    <div>
      <AdminNav />
      <div className="container">
        <h1 className="mb-4">Order Management</h1>

        {/* Bộ lọc */}
        <div className="d-flex mb-4">
          <Form.Control
            type="text"
            placeholder="Lọc theo tên khách hàng"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            className="me-2"
          />
          <Form.Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="me-2"
          >
            <option value="">Lọc theo trạng thái</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
          </Form.Select>
          <Form.Control
            type="date"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          />
        </div>

        {/* Bảng quản lý đơn hàng */}
        <table className="table table-dark table-striped table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Tên khách hàng</th>
              <th>Trạng thái đơn hàng</th>
              <th>Điểm đã đặt</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, index) => (
              <tr key={order._id}>
                <td>{index + 1}</td>
                <td>{order.customerInfo.name}</td>
                <td>{order.orderStatus}</td>
                <td>{order.selectedPoints.map(point => `${point.pointName} (${point.date})`).join(', ')}</td>
                <td>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() => handleViewOrderDetails(order)}
                  >
                    Xem chi tiết
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Modal hiển thị chi tiết đơn hàng */}
        <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-dialog-scrollable">
          <Modal.Header closeButton>
            <Modal.Title>Chi tiết đơn hàng</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOrder && (
              <>
                <h5>Thông tin khách hàng</h5>
                <table className="table table-dark table-striped table-bordered table-hover">
                  <tbody>
                    <tr><td>Tên</td><td>{selectedOrder.customerInfo.name}</td></tr>
                    <tr><td>Số điện thoại</td><td>{selectedOrder.customerInfo.phone}</td></tr>
                    <tr><td>Email</td><td>{selectedOrder.customerInfo.email}</td></tr>
                    <tr><td>Số lượng khách</td><td>{selectedOrder.customerInfo.guestCount}</td></tr>
                    <tr><td>Nhân dịp</td><td>{selectedOrder.customerInfo.occasion}</td></tr>
                    <tr><td>Thời gian đến</td><td>{selectedOrder.customerInfo.time}</td></tr>
                  </tbody>
                </table>

                <h5 className="mt-4">Thông tin đơn hàng</h5>
                <table className="table table-dark table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Điểm</th>
                      <th>Ngày</th>
                      <th>Trạng thái</th>
                      <th>Giá</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.selectedPoints.map((point, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{point.pointName}</td>
                        <td>{point.date}</td>
                        <td>{point.status}</td>
                        <td>{point.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleUpdateOrderStatus(selectedOrder._id, 'Completed')}>
              Xác nhận
            </Button>
            <Button variant="danger" onClick={() => handleDeleteOrder(selectedOrder._id)}>
              Xóa
            </Button>
            
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default OrderManagement;
